import {
  Button as UIButton,
  ButtonAppearance,
  ButtonType,
} from '@workos-inc/ui-kit';
import React, { CSSProperties, FC, MouseEvent, useContext } from 'react';
import { ThemeContext } from 'styled-components';

type ButtonProps = {
  appearance?: ButtonAppearance;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
  isLoading?: boolean;
  isOutline?: boolean;
  type?: ButtonType;
};

export const Button: FC<Readonly<ButtonProps>> = ({
  appearance,
  children,
  disabled,
  onClick,
  isLoading,
  isOutline,
  style = {},
  type,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <UIButton
      appearance={appearance}
      disabled={disabled}
      isCompressed={true}
      isLoading={isLoading}
      isOutline={isOutline}
      onClick={onClick}
      style={{
        backgroundColor: appearance ? undefined : theme.primary,
        border: appearance ? undefined : theme.primary,
        display: 'inline-flex',
        ...style,
      }}
      type={type}
    >
      {children}
    </UIButton>
  );
};
